<template>
  <b-row class="lists page-my-cards">
    <b-col>
      <PageHeader :title="$t('general.my-wallets.title')" />

      <div class="post-list">
        <div class="page-my-cards__tabs">
          <CRadio
            v-model="selectedTab"
            v-for="(item, index) in tabs"
            :key="`${index + '-tab'}`"
            :name="item.name"
            :value="item"
            group-name="user-tab"
            class="user-tab"
          >
            <template #default="{ isActive, value: tab }">
              <div
                class="user-tab__item"
                :class="{ 'user-tab__item--active': isActive }"
              >
                <span class="user-tab__font"> {{ tab.content }} </span>
              </div>
            </template>
          </CRadio>
        </div>
      </div>

      <div class="post-list__posts">
        <InfinityScroll
          v-if="cardList.list && cardList.list.length"
          :items="cardList.list"
          :has-more="cardList.hasMoreCards"
          @rich-end="cardList.loadMore"
        >
          <template #default="{ item: card }">
            <WalletCard v-if="selectedTab.id === walletsTypes.cards" />
            <TransactionCard :card="card" v-else />
          </template>
        </InfinityScroll>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import PageHeader from "@/layout/PageHeader.vue";
import CRadio from "@/features/ui/CRadio.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";

import { mapActions, mapGetters } from "vuex";
import TransactionCard from "@/features/components/TransactionCard.vue";
import WalletCard from "@/features/components/WalletCard.vue";

const walletsTypes = {
  cards: "cards",
  history: "history",
};
export default {
  name: "PageMyWallets",
  components: {
    WalletCard,
    TransactionCard,
    InfinityScroll,
    CRadio,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      lists: [],
      walletsTypes,
      selectedTab: {
        id: walletsTypes.cards,
        content: this.$t("general.my-wallets.wallet"),
      },
    };
  },
  mounted() {
    this.initialLoadCards();
  },
  computed: {
    ...mapGetters({
      wallet: "wallet/wallet",
      history: "wallet/history",
    }),
    cardList() {
      if (this.selectedTab.id === this.walletsTypes.cards) {
        // wallets
        return {
          list: [this.wallet],
          hasMoreCards: null,
          loadMore: () => {},
        };
      }
      // history
      return {
        list: this.historyList,
        hasMoreCards: Boolean(this.history?.next_page_url),
        loadMore: () => this.loadHistory({ page: this.history?.page + 1 }),
      };
    },

    tabs() {
      return [
        {
          id: walletsTypes.cards,
          content: this.$t("general.my-wallets.wallet"),
        },
        {
          id: walletsTypes.history,
          content: this.$t("general.my-wallets.history"),
        },
      ];
    },

    historyList() {
      return this.history?.data?.map(item => {

        const title = item.info.payment_method?.title

        return {
          ...item,
          title,
        }
      }) || []
    }
  },
  methods: {
    ...mapActions({
      fetchWalletHistory: "wallet/fetchWalletHistory",
      fetchWallet: "wallet/fetchWallet",
    }),

    createQueryConfig(config) {
      return {
        page: this.history.page,
        limit: this.history.per_page,

        ...config,
      };
    },

    openAddCardPopup() {
      this.$popup.open("AddCardPopup");
    },

    loadWallet() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.fetchWallet().catch(this.checkErrors);
      });
    },

    loadHistory(config) {
      const queryConfig = this.createQueryConfig(config);

      this.waitRequest(() => {
        return this.fetchWalletHistory(queryConfig).catch(this.checkErrors);
      });
    },

    async initialLoadCards() {
      this.$showSpinner();

      this.loadWallet().then(() => {
        return this.fetchWalletHistory({ page: 1 }).catch(this.checkErrors);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.lists {
  a {
    text-decoration: none;
    color: var(--dark);

    &:hover {
      color: var(--dark);
      text-decoration: none;
      background: var(--light);
    }
  }
}

.page-my-cards {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
  }

  &__search {
    margin-top: em(16);
  }

  &__tabs {
    display: flex;
    flex-wrap: nowrap;
  }

  &__group {
    margin: em(22) em(17);
  }
}

.collections-header-button {
  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.post-list {
  margin-right: -15px;
  margin-left: -15px;

  &__posts {
    margin-top: 1px;
  }
}
</style>
