<template>
  <div class="wallet-card">
    <div class="wallet-card__header">
      <div class="wallet-card__card">
        <div class="wallet-card__image-wrapper">
          <CIcon
            name="wallet-money"
            path="wallet"
            class="wallet-card__icon wallet-card__icon--wallet"
          />
        </div>

        <div class="wallet-card__description">
          <div class="wallet-card__title">
            <span class="wallet-card__font wallet-card__font--balance">
              {{ balance }}
            </span>
          </div>

          <span class="wallet-card__font wallet-card__font--description">
            {{ $t("general.my-wallets.balance") }}
          </span>
        </div>
      </div>
    </div>

    <div class="wallet-card__button">
      <CButton
        variant="primary"
        align="left"
        block
        @click.prevent="updateBalance"
      >
        {{ $t("general.my-wallets.button") }}
      </CButton>
    </div>
  </div>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";
import CButton from "@/features/ui/СButton.vue";
import { currencyFormat } from "@/tools/helpers";
import { mapGetters } from "vuex";

export default {
  name: "WalletCard",
  components: {
    CButton,
    CIcon,
  },
  computed: {
    ...mapGetters({
      wallet: "wallet/wallet",
    }),
    balance() {
      const balance = parseFloat(this.wallet?.balance || 0);

      return this.currencyFormat(balance);
    },
  },
  methods: {
    currencyFormat,

    updateBalance() {
      this.$popup.open("AddFundsPopup");
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  height: 138px;
  padding: 16px 15px;
  margin-top: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__image {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin-left: em(9);
  }

  &__font {
    &--balance {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 20px;
      color: $app-black;
    }

    &--description {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      color: $app-black;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
  }

  &__button {
    margin-top: em(16);
  }

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: em(15);
    }

    &:active {
      transform: none;
      filter: none;
    }
  }
}
</style>
